import React, { Component, Fragment } from "react";
import QRCode from 'qrcode.react';
import CONFIG from '../../environments/env';

// Style
import "../../scss/global.scss";

// Image
import success from "../../img/calendar.jpg";
import pix_copiado from "../../img/pix_copiado.png";

// Services
import { ListServices } from "../../services/ListServices";

export default class Success extends Component {
    state = {
        dadosClinica: [],
        modalDuvidas: false,
        modalPix: false
    };

    componentDidMount() {
        window.localStorage.setItem("user", []);
        this.loadInfo();
    }

    async loadInfo() {
        const information = await ListServices.listInfo();
        // console.log(information);
        if (information.hasOwnProperty("success")) {
            this.setState({
                dadosClinica: information.success[0]
            });
        } else {
            // window.location.href = 'https://www.steticmob.com.br';
            // alert(information.error);
        }
    }

    openMap() {
        const { dadosClinica } = this.state;
        window.open(
            `https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${dadosClinica.endereco},+${dadosClinica.cep}`,
            "_blank"
        );
        sessionStorage.removeItem('qrcodepix');
    }

    reload() {
        const link = sessionStorage.getItem('link_agenda');
        // window.location.reload();
        if(link != null || link != undefined){
            window.location.href = `${window.location.origin}${window.location.pathname}?empresa=${link}`;
            sessionStorage.removeItem('qrcodepix');
            sessionStorage.removeIremoveItemtem('link_agenda');
        }else{
            window.location.reload();
        }
    }

    copy(stringCode) {
        // navigator.clipboard.writeText(stringCode).then(() => {
        //     this.modalPix();
        //     alert("Código PIX copiado com sucesso!");
        // }).catch((err) => {
        //     console.log("ERRO AO COPIAR O PIX: ", err);
        //     alert(`ERRO AO COPIAR O PIX: ${err}`);
        // });
        const textArea = document.createElement("textarea");
        textArea.value = stringCode;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy');
            alert("Código PIX copiado com sucesso!");
        } catch (err) {
            alert(`ERRO AO COPIAR O PIX: ${err}`);
        }
        document.body.removeChild(textArea);
    }

    modalDuvida(){
        this.setState({
            modalDuvidas: !this.state.modalDuvidas
        });
    }

    modalPix(){
        this.setState({
            modalPix: !this.state.modalPix
        });
    }

    render() {
        const qrCode = sessionStorage.getItem('qrcodepix');
        var showCode = false;
        var theCode, stringCode;
        if(qrCode != null || qrCode != undefined){
            showCode = true;
            theCode = <QRCode value={qrCode} />;
            stringCode = qrCode;
        }
        console.log('showCode: ', showCode);
        return (
            <Fragment>
                <div className="success">
                    {/* <img
                        src={success}
                        alt={"Agendamento efetuado com sucesso"}
                    /> */}

                    <div className="box-qrcode" 
                    style={{
                        display: showCode ? "block" : "none",
                    }}>
                        <div className="title">Pagamento</div>
                        <p>Para finalizar o pagamento escaneie o QRCode abaixo ou copie a chave PIX.</p>
                        <div>
                            {theCode}
                        </div>
                        <div className="action action-2">
                            <button
                                className="next"
                                onClick={() => {
                                    this.copy(stringCode);
                                }}
                            >
                                Copiar Chave PIX
                            </button>
                        </div>
                        <div className="box-alert-new">
                            O seu horário fica reservado por 10 minutos aguardando pagamento, caso não ocorra, ele será cancelado automaticamente.
                        </div>
                        {/* <p className="info-pix">Falta pouco para <strong>confirmar seu agendamento</strong>, realize o <strong>pagamento através do PIX, ESCOLHA umas das formas abaixo, para finalizar o pagamento:</strong></p> */}
                        {/* <div className="box-alert">
                            Se o pagamento <strong>não for feito pelos dados abaixo em até 2h</strong> o seu <strong>agendamento será cancelado automaticamente.</strong>
                        </div> */}
                        {/* <p className="copy-code">copie e cole o código</p>
                        <div className="box-code">
                            {stringCode}
                        </div> */}
                        {/* <p className="copy-code">ou EscaneiE o QrCode:</p> */}
                        {/* <div>
                            {theCode}
                        </div> */}
                        <button className="btn-duvida" onClick={() => {
                                this.modalDuvida();
                            }}>
                            Dúvidas? Veja como é fácil fazer um Pix!
                        </button>
                    </div>
                    <div
                    style={{
                        display: !showCode ? "block" : "none",
                    }}>
                        <div className="title">Pagamento</div>
                        
                        <div className="box-alert">
                            <p>Agendamento feito com sucesso!</p>
                        </div>
                    </div>
                    
                    <div className="action">
                        <button
                            className="next"
                            onClick={() => {
                                this.reload();
                            }}
                        >
                            Criar novo
                        </button>
                        <button
                            className="next"
                            onClick={() => {
                                this.openMap();
                            }}
                        >
                            Ver mapa
                        </button>
                    </div>
                    <div className="modal-duvida"
                    style={{
                        display: this.state.modalDuvidas ? "block" : "none",
                    }}>
                        <div className="body-duvida">
                            <button className="btn-close" onClick={() => {
                                    this.modalDuvida();
                                }}>
                                <strong>X</strong>
                            </button>
                            <h3 className="title-duvida">
                                Veja como é fácil<br/>fazer um pix:
                            </h3>
                            <ol className="lista-duvida">
                                <li>Copie a chave pix ou use o qrcode</li>
                                <li>Abra o aplicativo do seu banco ou internet banking e procure o menu pix</li>
                                <li>Escolha a opção pix copia e cola ou pagamento pix</li>
                                <li>Cole o código e confirme o pagamento</li>
                                <li>Pronto! Pagamento realizado com sucesso!</li>
                            </ol>
                        </div>
                    </div>

                    <div className="modal-duvida"
                    style={{
                        display: this.state.modalPix ? "block" : "none",
                    }}>
                        <div className="body-duvida">
                            <button className="btn-close" onClick={() => {
                                    this.modalPix();
                                }}>
                                <strong>X</strong>
                            </button>
                            <div className="success-copied">
                                <img src={pix_copiado}/> Código Pix Copiado
                            </div>
                            <h3 className="title-duvida">
                                Próximos passos
                            </h3>
                            <ol className="lista-duvida">
                                <li>Abra o aplicativo do seu banco ou internet banking</li>
                                <li>procure e acesse o menu pix</li>
                                <li>escolha a opção pix copia e cola ou pagemento pix</li>
                                <li>cole o código e confirme o pagamento</li>
                                <li>Pronto! Pagamento realizado com sucesso!</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
