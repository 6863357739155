import React, { Component } from "react";

// Services
import { ListServices } from "../../services/ListServices";

// Moment
import moment from "moment";

// Components
import ListService from "./ListService";
import Date from "./Date";
import UserIdentification from "./UserIdentification";
import Success from "./Success";

import CONFIG from '../../environments/env';

export default class Service extends Component {
    chooseService = this.chooseService.bind(this);
    chooseDay = this.chooseDay.bind(this);
    chooseTime = this.chooseTime.bind(this);
    updateInfo = this.updateInfo.bind(this);

    state = {
        step: 1,
        services: [],
        service: [],
        day: "",
        date: [],
        user: [],
        pgto: [],
        loading: false,
        session: ""
    };

    componentDidMount() {
        this.loadService();
        this.loadInfo();
        this.thereCustomer();
        window.localStorage.setItem("user", []);
    }

    // async startSession() {
    //     await ListServices.sessaoPg({
    //         token: CONFIG.TOKEN,
    //         key: CONFIG.KEY
    //     }).then(res => {
    //         // console.log("---->>>>>> PG", res.id);
    //         this.setState({
    //             session: res.id
    //         });
    //     });
    // }

    async loadInfo() {
        const information = await ListServices.listInfo();
        this.setState({
            user: information.success
        });
    }

    async thereCustomer(){
        const customer = await ListServices.dataPix();
        // console.log('thereCustomer: ', customer);
        if(customer.success){
            sessionStorage.setItem('qrcodepix', customer.success[0].qrcode_pix);
            sessionStorage.setItem('link_agenda', customer.success[0].link_agenda);
            this.setState({
                step: 4
            });
        }
    }

    async loadService() {
        const services = await ListServices.listService();
        const filter = services.success.filter(prop => parseFloat(prop.valor) == 0.00 || parseFloat(prop.valor) >= 10.00);
        console.log('services services services: ', filter);

        this.setState({
            services: filter || [],
            loading: true
        });
    }

    // Próxima etapa
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
        window.scrollTo(0, 0);
    };

    // Etapa Anterior
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
        window.scrollTo(0, 0);
    };

    chooseService(obj) {
        this.setState({
            service: obj
        });
    }

    chooseDay(obj) {
        // this.setState({
        //     day: obj
        // });
        this.setState({
            day: moment(obj).format("DD/MM/YYYY")
        });
    }

    chooseTime(start, finish) {
        // var sumTime = 0.0;
        // this.state.service.forEach(element => {
        //     sumTime += parseFloat(element.tempo);
        // });
        // const finishTime = moment(obj, "hh:mm:ss")
        //     .add(this.state.service.tempo, "minutes")
        //     .format("HH:mm");
        // console.log(finishTime);
        this.setState({
            date: {
                start: start,
                finish: finish
            }
        });
    }

    updateInfo(obj) {
        this.setState({
            user: [...this.state.user, obj]
        });
    }

    render() {
        // console.log(this.state.day);
        switch (this.state.step) {
            case 1:
                return (
                    <ListService
                        services={this.state.services}
                        chooseService={this.chooseService}
                        nextStep={this.nextStep}
                        loading={this.state.loading}
                        infoEmpresa={this.state.infoEmpresa}
                    />
                );
            case 2:
                return (
                    <Date
                        date={this.state.date}
                        chooseDay={this.chooseDay}
                        chooseTime={this.chooseTime}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        hours={this.state.date}
                        userInfo={this.state.user}
                        data={this.state.day}
                        tempoServico={this.state.service.tempo}
                    />
                );
            case 3:
                return (
                    <UserIdentification
                        userInfo={this.state.user}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        serviceValue={this.state.service}
                        data={this.state.day}
                        hours={this.state.date}
                        loading={this.state.loading}
                        session={this.state.session}
                        banco={this.state.user[0].banco}
                        exibe={this.state.user[0].exibe}
                    />
                );
            default:
                return <Success />;
        }
    }
}
