import React, { Component, Fragment } from "react";

// Style
import "../../scss/global.scss";

// Select
import Select from "react-select";

// Icons
import iconDelete from "../../icon/minus.svg";
import iconLoading from "../../icon/ajax-loader.gif";

export default class ListService extends Component {
    state = {
        item: [],
        disabled: true,
        msg: ""
    };

    chooseService(obj) {
        this.props.chooseService(obj);
        this.props.nextStep();
    }

    formartCurrency(value) {
        const money = parseFloat(value),
            convert = money.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
            });
        return convert;
    }

    chooseItem = itemSelect => {
        const { item } = this.state;
        // console.log(itemSelect);
        this.setState({
            item: itemSelect,
            disabled: false,
            msg: ""
        });
        // const busca = item.findIndex(e => e.id === itemSelect.id);
        // if (busca === -1) {
        //     this.setState({
        //         item: itemSelect,
        //         disabled: false,
        //         msg: ""
        //     });
        // } else {
        //     this.setState({
        //         msg: "Esse serviço já foi escolhido"
        //     });
        // }
    };

    removeItem = id => {
        const itens = this.state.item.filter(i => i.id !== id);
        this.setState({
            item: itens,
            disabled: itens.length !== 0 ? false : true
        });
    };

    componentDidMount() {
        const info = this.props.infoEmpresa;
        // console.log("Dados da empresa", info);
    }

    render() {
        const { services } = this.props;
        // console.log('--->>>>>: ', services);
        const { item, disabled, msg } = this.state;
        const loading = !this.props.loading;
        // const options = [];
        let noService;
        let free;

        let opt = services.map(prop => {
            return {
                ...prop,
                value: prop.nome,
                label: prop.nome
            };
        });

        this.options = opt;

        console.log('OPT: ', this.options);

        if (item.length === 0) {
            noService = "Nenhum serviço foi escolhido";
        }

        if(item.valor === '0.00'){
            free = 'Grátis'
        }else{
            free = this.formartCurrency(item.valor);
        }

        return (
            <Fragment>
                <div className="services">
                    <div className={loading ? "loading" : "no-loading"}>
                        <img
                            src={iconLoading}
                            alt="Carregando"
                            className="ajax-load"
                        />
                    </div>

                    <div className="title">Escolha o Serviço desejado</div>
                    <div className="list-service">
                        <Select
                            options={this.options}
                            onChange={this.chooseItem}
                            noOptionsMessage={() => "Nenhum serviço encontrado"}
                            placeholder={"Escolha um serviço"}
                        />

                        <div className="wrapper">
                            <span className="repeat">{msg}</span>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Serviço</th>
                                        <th>Duração</th>
                                        <th>Valor</th>
                                        {/* <th>Excluir</th> */}
                                    </tr>
                                </thead>
                                {
                                    <tbody
                                        style={{
                                            display:
                                                item.length !== 0
                                                    ? "table-row-group"
                                                    : "none"
                                        }}
                                    >
                                        <tr>
                                            <td>{item.nome}</td>
                                            <td>{item.tempo} min</td>
                                            <td>
                                                {free}
                                            </td>
                                            {/* <td>
                                                <button
                                                    onClick={() =>
                                                        this.removeItem(item.id)
                                                    }
                                                >
                                                    <img
                                                        className="delete"
                                                        src={iconDelete}
                                                        alt={"Deletar"}
                                                    />
                                                </button>
                                            </td> */}
                                        </tr>
                                    </tbody>
                                }
                            </table>

                            <div className="no-service">{noService}</div>

                            <div className="action">
                                <button
                                    className="chooseService"
                                    onClick={() => this.chooseService(item)}
                                    disabled={disabled}
                                >
                                    {"Continuar com o agendamento"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
