import React, { Component, Fragment } from "react";

// Style
import "../../scss/global.scss";

// Moment
import moment from "moment";

// Components
import Calendar from "react-calendar";
import { TimeFormat } from "../masks/Masks";

// Services
import { ListServices } from "../../services/ListServices";

import iconLoading from "../../icon/ajax-loader.gif";

import Select from "react-select";

import CONFIG from '../../environments/env';

export default class Date extends Component {
    state = {
        data: "",
        loading: false,
        horaCorreta: false,
        hora_inicio: "",
        hora_final: "",
        horariosDisponiveis: [],
        hora_escolhida: []
    };

    async chooseDataTime() {
        //this.props.nextStep();
        // hora_inicial, hora_final, data, empresa_id, key, token;
        // this.setState({
        //     loading: true
        // });
        // await ListServices.compareTime({
        //     token: "token",
        //     key: "key",
        //     empresa_id: this.props.userInfo[0].empresa_id,
        //     data: this.props.data,
        //     hora_inicial: this.state.hora_inicio,
        //     hora_final: this.state.hora_final
        // }).then(res => {
        //     if (res.hasOwnProperty("success")) {
        //         this.props.nextStep();
        //     } else {
        //         // window.location.href = 'https://www.steticmob.com.br';
        //         alert(res.error);
        //     }
        //     this.setState({
        //         loading: false
        //     });
        // });
        this.props.nextStep();
    }

    prevStep() {
        this.props.prevStep();
    }

    onChange = async date => {
        this.setState({
            data: date,
            loading: true
        });
        this.props.chooseDay(date);
        const day = moment(date).format("DD/MM/YYYY"),
            validarHorario = await ListServices.validarHorario({
                token: CONFIG.TOKEN,
                key: CONFIG.KEY,
                empresa_id: this.props.userInfo[0].empresa_id,
                tempo: this.props.tempoServico,
                data: day
            }).then(res => {
                // console.log("RESSSS", res);
                if (res.hasOwnProperty("success")) {
                    let opt =
                        res.success.map(prop => {
                            return {
                                ...prop,
                                value:
                                    prop.hora_inicio + " - " + prop.hora_final,
                                label:
                                    prop.hora_inicio + " - " + prop.hora_final
                            };
                        }) || [];
                    this.setState({
                        horariosDisponiveis: opt,
                        loading: false
                    });
                } else {
                    alert(res.error);
                    this.setState({
                        loading: false
                    });
                }
            });
    };

    startTime = event => {
        const value = event.target.value;
        const format = TimeFormat(value);
        this.props.chooseTime(format);
        const certo = value.length === 5 ? false : true;
        if (certo) {
            this.setState({
                horaCorreta: false
            });
        } else {
            this.setState({
                horaCorreta: true
            });
        }
    };

    chooseItem = itemSelect => {
        this.setState({
            hora_inicio: itemSelect.hora_inicio,
            hora_final: itemSelect.hora_final,
            hora_escolhida: itemSelect
        });
        this.props.chooseTime(itemSelect.hora_inicio, itemSelect.hora_final);
    };

    render() {
        // console.log('Data: ', );
        // console.log("Start: ", this.props.date);
        const { loading } = this.state;
        var disabled;
        if (this.state.data !== "" && this.state.hora_escolhida.length !== 0) {
            disabled = false;
        } else {
            disabled = true;
        }
        return (
            <Fragment>
                <div className="calendar">
                    <div className={loading ? "loading" : "no-loading"}>
                        <img
                            src={iconLoading}
                            alt="Carregando"
                            className="ajax-load"
                        />
                    </div>
                    <div className="title">Escolha a Data e Horário</div>
                    <Calendar
                        onChange={this.onChange}
                        minDate={moment().toDate()}
                    />
                    <div className="hours">
                        <Select
                            options={this.state.horariosDisponiveis}
                            onChange={this.chooseItem}
                            noOptionsMessage={() =>
                                "Nenhum horário foi encontrado"
                            }
                            placeholder={
                                "Escolha um horário para o agendamento"
                            }
                        />
                        {/* <input
                            placeholder="Horário Inicial"
                            onChange={this.startTime}
                            value={this.props.date.start}
                            maxLength="5"
                            type="tel"
                        />

                        <input
                            disabled
                            placeholder="Horário Final"
                            value={this.props.date.finish}
                        /> */}
                    </div>
                    <div className="action">
                        
                        <button
                            className="prev"
                            onClick={() => this.prevStep()}
                        >
                            Etapa Anterior
                        </button>
                        <button
                            className="next"
                            onClick={() => this.chooseDataTime()}
                            disabled={disabled}
                        >
                            Próxima Etapa
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}
