import { ApiService, viaCep, consultarCliente, codePix } from "./ApiService";
export const ListServices = {
    listService() {
        return ApiService.get("lista_servicos_web");
    },
    dataPix() {
        return codePix.get("lista_info_transacao_web");
    },
    listInfo() {
        return ApiService.get("lista_info_check_web");
    },
    consultaCliente(obj) {
        return consultarCliente.get("verificar_cliente_pg_online", obj);
    },
    cadastrarCliente(obj) {
        return ApiService.post("cadastro_customer", obj);
    },
    searchZipCode(cep) {
        return viaCep.get(cep);
    },
    createCustomer(obj) {
        return ApiService.post("cadastro_cliente_web", obj);
    },
    cupom(obj) {
        return ApiService.post("aplicar_cupom", obj);
    },
    createService(obj) {
        return ApiService.post("cadastro_atendimento", obj);
    },
    compareTime(obj) {
        return ApiService.post("verifica_horario_disponivel", obj);
    },
    sessaoPg(obj) {
        return ApiService.post("get_session", obj);
    },
    validarHorario(obj) {
        return ApiService.post("buscar_hora_disponivel", obj);
    }
};
