import CONFIG from '../environments/env';
const api = CONFIG.API,
    url = new URL(CONFIG.EMPRESA),
    search = url.searchParams.get("empresa");
    console.log('search: ', search);

const api_pix = CONFIG.PIX,
    get_pix = new URL(CONFIG.PIX),
    pix = get_pix.searchParams.get("transacao");

    console.log('PIX: ', pix);

export const ApiService = {
    get(endpoint) {
        console.log(`URL EXEMPLO: ${api}${endpoint}.php?empresa_link=${search}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`);
        return fetch(
            `${api}${endpoint}.php?empresa_link=${search}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`
        ).then((res) => res.json());
    },
    post(endpoint, data) {
        return fetch(`${api}${endpoint}.php`, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .catch((err) => console.log(err));
    },
};

export const codePix = {
    get(endpoint) {
        console.log(`PIX DO CLIENTE: ${api}${endpoint}.php?transacao=${pix}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`);
        return fetch(
            `${api}${endpoint}.php?transacao=${pix}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`
        ).then((res) => res.json());
    }
} 

export const viaCep = {
    get(cep) {
        return fetch(`https://viacep.com.br/ws/${cep}/json/`).then((res) =>
            res.json()
        );
    },
};

export const consultarCliente = {
    get(endpoint, obj) {
        console.log(`${api}${endpoint}.php?nome=${obj.nome}&telefone=${obj.telefone}&empresa_id=${obj.empresa_id}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`);
        return fetch(`${api}${endpoint}.php?nome=${obj.nome}&telefone=${obj.telefone}&empresa_id=${obj.empresa_id}&token=${CONFIG.TOKEN}&key=${CONFIG.KEY}`).then((res) =>
            res.json()
        );
    },
};