import React, { Component } from "react";

// Components
import Service from "./components/Service";

// Images
import logo from "../img/logo.png";
import ssl from "../img/sslseguro.png";
import comodo from "../img/comodo.png";

// Services
import { ListServices } from "../services/ListServices";

export default class app extends Component {
    state = {
        dadosClinica: [],
    };

    componentDidMount() {
        this.loadInfo();
    }

    async loadInfo() {
        const information = await ListServices.listInfo();
        // console.log(information);
        if (information.hasOwnProperty("success")) {
            this.setState({
                dadosClinica: information.success[0],
            });
            console.log('DADOS: ', this.state.dadosClinica);
        } else {
            // window.location.href = 'https://www.steticmob.com.br';
            // alert(information.error);
            this.thereCustomer();
        }
    }

    async thereCustomer(){
        const customer = await ListServices.dataPix();
        this.setState({
            dadosClinica: customer.success[0],
        });
        console.log('thereCustomer: ', customer.success[0]);
    }

    render() {
        const { dadosClinica } = this.state;
        // console.log(dadosClinica);
        var divStyle = {
            color: "white",
            backgroundImage: "url(" + dadosClinica.caminho + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            backgroundPosition: "center",
            margin: '0 auto 30px auto',
            height: 100,
            width: 150,
            borderRadius: '05px'
        };

        const renderLogo = () => {
            if (dadosClinica.caminho != null) {
                // return <div id="imagem-perfil" style={divStyle}></div>;
                return <div id="imagem-perfil" style={divStyle}></div>;
            } else {
                return (
                    <div>
                        <h1>{dadosClinica.nome}</h1>
                    </div>
                )
            }
        }

        return (
            <div className="container">
                <div className="header">
                    {renderLogo()}
                    {/* <img src={logo} alt="Stetic Mob - Agendamento" /> */}
                    {/* <div id="imagem-perfil" style={divStyle}></div> */}
                </div>
                <Service />
                <div className="footer">
                    {/* <div id="imagem-perfil" style={divStyle}></div> */}
                    <strong>{dadosClinica.nome}</strong>
                    <br />
                    Horário de funcionamento:
                    <br />
                    {dadosClinica.horario}
                    <br />
                    Telefone: {dadosClinica.phone}
                    <br />
                    E-mail:
                    <a href={"mailto:" + dadosClinica.email}>
                        {dadosClinica.email}
                    </a>
                    <br />
                    Endereço: {dadosClinica.endereco},{" "}
                    {dadosClinica.complemento} - CEP: {dadosClinica.cep}
                    <br />
                    <a
                        target="_blank"
                        href={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${dadosClinica.endereco},+${dadosClinica.cep}`}
                    >
                        Ver mapa
                    </a>
                    <br />
                    <br />
                    <img src={logo} alt="Stetic Mob - Agendamento" className="logo-footer"/>
                    <img
                        src={ssl}
                        alt="Certificado de segurança"
                        style={{ width: 120 + "px" }}
                    />
                </div>
            </div>
        );
    }
}
