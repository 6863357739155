const CONFIG = {
    API: null,
    TOKEM: null,
    KEY: null,
    EMPRESA: '',
    PAGARME: '',
    PIX: ''
}

if (window.location.href.indexOf('https://agendamento.steticmob.app') !== -1) {
    console.log('---->>>>> AMBIENTE DE PRODUÇÃO!');
    CONFIG.API = "https://steticmon-api-prod.herokuapp.com/_api/";
    CONFIG.TOKEN = '5351d642f50b1a8c53ae3d0059ded14d';
    CONFIG.KEY = 'e9d8f5a6bf28de23bae0271c40a10c14';
    CONFIG.PAGARME = 'ek_live_ZFbZJ9HImmuWJYFQ8dGGJJu7t47vqC';
    CONFIG.EMPRESA = window.location.href;
    CONFIG.PIX = window.location.href;
    // https://agendamento.steticmob.app/
} else if (window.location.href.indexOf('https://hmg.steticmob.app/') !== -1) {
    console.log('---->>>>> AMBIENTE DE HOMOLOGAÇÃO!');

    // CONFIG.API = "https://steticmon-api-prod.herokuapp.com/_api/";
    // CONFIG.TOKEN = '5351d642f50b1a8c53ae3d0059ded14d';
    // CONFIG.KEY = 'e9d8f5a6bf28de23bae0271c40a10c14';
    // CONFIG.PAGARME = 'ek_live_ZFbZJ9HImmuWJYFQ8dGGJJu7t47vqC';
    // CONFIG.EMPRESA = window.location.href;

    CONFIG.API = "https://steticmob-app-api.herokuapp.com/_api/";
    CONFIG.TOKEN = 'token';
    CONFIG.KEY = 'key';
    CONFIG.PAGARME = 'ek_live_ZFbZJ9HImmuWJYFQ8dGGJJu7t47vqC';
    CONFIG.EMPRESA = window.location.href;
    CONFIG.PIX = window.location.href;
} else {
    console.log('---->>>>> AMBIENTE DE DESENVOLVIMENTO!');
    CONFIG.API = "https://steticmob-app-api.herokuapp.com/_api/";
    CONFIG.TOKEN = '5351d642f50b1a8c53ae3d0059ded14d';
    CONFIG.KEY = 'e9d8f5a6bf28de23bae0271c40a10c14';
    CONFIG.PAGARME = 'ak_test_wwjsjA8I6tsI0u8306eQPfXAwWVfoP';
    CONFIG.EMPRESA = "https://hmg.steticmob.app/agenda/?empresa=teste-de-nome";
    CONFIG.PIX = window.location.href;
    // https://hmg.steticmob.app/agenda/
    // https://agendamento.steticmob.app/?empresa=salao-do-ricardao
    // ak_test_wwjsjA8I6tsI0u8306eQPfXAwWVfoP
}

export default CONFIG;

/**
 * Email: appios@steticmob.app
 * Senha: qwertyu
 */
