import React, { Component, Fragment } from "react";

// Style
import "../../scss/global.scss";

// Services
import { ListServices } from "../../services/ListServices";

// Mask
// import { CreditFormat } from '../masks/Masks';

// Icons
import cartaoCredito from "../../icon/credit-cards-payment.svg";
import cartaoPix from "../../icon/pix.svg";
import dinheiro from "../../icon/money-payment.svg";
import changePayment from "../../icon/change.svg";


import iconLoading from "../../icon/ajax-loader.gif";
import cardFront from "../../img/card_front.png";
import cardBack from "../../img/card_back.png";

import InputMask from "react-input-mask";

import CONFIG from '../../environments/env';

export default class UserIdentification extends Component {
    user = this.user.bind(this);
    card = this.card.bind(this);
    flip = this.flip.bind(this);
    verso = this.verso.bind(this);
    otherPayment = this.otherPayment.bind(this);
    chooseCountry = this.chooseCountry.bind(this);
    verificaCliente = this.verificaCliente.bind(this);

    state = {};

    componentDidMount() {
        this.setState(
            {
                validEmail: false,
                token: CONFIG.TOKEN,
                key: CONFIG.KEY,
                data: this.props.data,
                pessoa_id: this.props.userInfo[0].pessoa_id,
                cliente_id: 0,
                customer_id: null,
                servico_id: this.props.serviceValue,
                usuario_id: this.props.userInfo[0].usuario_id,
                empresa_id: this.props.userInfo[0].empresa_id,
                valor: 0.0,
                tipo_pagamento: "",
                hora_inicial: this.props.hours.start,
                hora_final: this.props.hours.finish,
                loading: !this.props.loading,
                name: "",
                name_cliente: "",
                email_cliente: "",
                telefone_cliente: "",
                pais_cliente: "BR",

                card_number: "",
                card_brand: "",
                card_valid: "",
                card_cvv: "",
                card_doc: "",
                name_card: "",
                data_nascimento: "",
                celular_titular: "",
                flip_card: false,

                cep_cliente: "",
                logradouro_cliente: "",
                complemento_cliente: "",
                numero_cliente: "",
                bairro_cliente: "",
                localidade_cliente: "",
                uf_cliente: "",

                cupom_nome: "",
                cupom_id: 0,
                disabled_cupom_btn: true,
                disabled_pgto_btn: true,
                disabled_cupom_input: false,

                pgCartao: true,
                card_hash: "",
                tokencartao: "",

                agendaapp: "web",

                otherPayment: false
            },
            () => {
                this.sumValues();
                this.temCupom();
            }
        );
        const local = window.localStorage.getItem("user");
        // console.log("Local Storage: ", local.length);
        if (local) {
            const dados = JSON.parse(window.localStorage.getItem("user"));
            this.setState({
                name: dados.name,
                email_cliente: dados.email_cliente,
                telefone_cliente: dados.telefone_cliente,
                cep_cliente: dados.cep_cliente,
                logradouro_cliente: dados.logradouro_cliente,
                complemento_cliente: dados.complemento_cliente,
                bairro_cliente: dados.bairro_cliente,
                localidade_cliente: dados.localidade_cliente,
                uf_cliente: dados.uf_cliente,
                validEmail: true,
            });
        }
        this.loadInfo();

        // const setSessionPg = window.PagSeguroDirectPayment.setSessionId(
        //     `${this.props.session}`
        // );
    }

    async loadInfo() {
        // const information = await ListServices.listInfo();

        // if (information.hasOwnProperty("success")) {
        //     // console.log("SASAAS");
        //     let pgCartao;
        //     if (information.success[0].autorizacao_pagseguro === 1) {
        //         pgCartao = true;
        //     } else {
        //         pgCartao = false;
        //     }
        //     this.setState({
        //         pgCartao,
        //     });
        // }
        // console.log("State: ", this.state);
    }

    prevStep = () => {
        this.props.prevStep();
    };

    nextStep = () => {
        this.props.nextStep();
    };

    otherPayment(){
        this.setState({
            otherPayment: true
        })
    }

    user(event) {
        const { target } = event,
            { name, value } = target;

        let valida, valor, pagar;

        if (this.state.name !== "" && this.state.telefone_cliente !== "") {
            console.log('SEGUEEE');
            pagar = false;
        } else {
            pagar = true;
        }

        if (name === "cupom_nome" && value !== "") {
            valida = false;
            valor = value.toUpperCase();
        } else {
            valida = true;
            valor = value;
        }



        this.setState({
            ...this.state,
            [name]: valor,
            disabled_cupom_btn: valida,
            disabled_pgto_btn: pagar
        });
    }

    async verificaCliente() {
        let obj = {
            nome: this.state.name,
            telefone: this.state.telefone_cliente,
            empresa_id: this.state.empresa_id
        }
        this.setState({
            loading: true
        });
        // console.log('ESTADO DA APLICACAO: ', this.state);
        await ListServices.consultaCliente(obj).then(data => {
            console.log('EXISTE: ', data);
            if (data.success) {
                if (data.success[0].customer_id == null) {
                    // pgCartao: false,
                    this.setState({
                        loading: false,
                        pgCartao: data.existe,
                        pessoa_id: data.success[0].pessoa_id,
                        customer_id: null
                    });
                    console.log('PESSOA COM ID: ', this.state);
                } else {
                    this.setState({
                        loading: false,
                        customer_id: data.success[0].customer_id
                    });
                    console.log('PESSOA 2 COM ID: ', this.state);
                }
            } else {
                this.setState({
                    loading: false,
                    pgCartao: data.existe
                });
                console.log('PESSOA SEM ID: ', this.state);
            }
        });
    }

    card(event) {
        const { target } = event,
            { name, value } = target;

        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    validYear(event){
        if(event.target.value.trim().length < 5){
            alert('Por favor, preencha corretamente a data de validade do cartão. Ex. 10/23.');
        }
    }

    validCvv(event){
        if(event.target.value.indexOf('.') != -1){
            alert('ATENÇÃO! O Número do CVV não pode conter "PONTO" ');
        }
        // if(event.target.value.trim() === '.'){
        //     alert('Número do CVV não pode conter "PONTO" ');
        // }
    }

    async flip() {
        await this.setState({
            flip_card: true
        })
    }

    async verso() {
        await this.setState({
            flip_card: false
        })
    }

    bandeiraCartao = (event) => {
        const { target } = event,
            { value } = target;
        this.setState({
            loading: true,
        });
    };

    chooseCountry(evt) {
        this.setState({
            pais_cliente: evt.target.value
        })
    }

    async pagar() {
        // console.log("ALL", this.state);

        // if(this.state.valor == 0){
        //     this.setState({
        //         tipo_pagamento: 'D'
        //     });
        // }

        if (this.state.tipo_pagamento === "C") {
            if (!this.state.pgCartao) {
                this.setState({
                    loading: true
                });
                await ListServices.cadastrarCliente({
                    token: CONFIG.TOKEN,
                    key: CONFIG.KEY,
                    nome: this.state.name,
                    email: this.state.email_cliente,
                    document: this.state.card_doc,
                    telefone: this.state.telefone_cliente,
                    nascimento: this.state.data_nascimento,
                    pais: this.state.pais_cliente,
                    empresa_id: this.state.empresa_id,
                    cliente_id: this.state.pgCartao ? this.state.pessoa_id : null
                }).then(data => {
                    if (data.error) {
                        console.log(data.error);
                        alert(data.error);
                        this.setState({
                            loading: false
                        });
                    } else {
                        console.log('Cadastro do Cliente: ', data);
                        this.setState({
                            customer_id: data.success.customer_id
                        });
                        this.pgtoCartao();
                    }
                })
            } else {
                this.pgtoCartao();
            }
        } if (this.state.tipo_pagamento === "P") {
            console.log('Pagamento com pix: ', !this.state.pgCartao, this.state.pgCartao);
            console.log('Pagamento com pix: ', this.state.customer_id, this.state.customer_id == null);
            if (!this.state.pgCartao || this.state.customer_id == null) {
                console.log('ENTROU NESSE IF')
                this.setState({
                    loading: true
                });
                await ListServices.cadastrarCliente({
                    token: CONFIG.TOKEN,
                    key: CONFIG.KEY,
                    nome: this.state.name,
                    email: this.state.email_cliente,
                    document: this.state.card_doc,
                    telefone: this.state.telefone_cliente,
                    nascimento: this.state.data_nascimento,
                    pais: this.state.pais_cliente,
                    empresa_id: this.state.empresa_id,
                    cliente_id: this.state.pgCartao ? this.state.pessoa_id : null
                }).then(data => {
                    if (data.error) {
                        console.log(data.error);
                        alert(data.error);
                        this.setState({
                            loading: false
                        });
                    } else {
                        console.log('Cadastro do Cliente: ', data);
                        this.setState({
                            customer_id: data.success.customer_id
                        });
                        this.fechamento();
                    }
                })
            }else{
                this.fechamento();
            }
        } 
        
        // else {
        //     this.fechamento();
        // }

        // console.log("AO FINALZIAR", this.state);
    }

    pgtoCartao() {

        const dataValidade = this.state.card_valid.split('/');
        // const dataValidade = this.state.card_valid;
        // ${dataValidade[1].slice(-2)}
        let card = {
            card_holder_name: this.state.name_card,
            card_expiration_date: `${dataValidade[0]}/${dataValidade[1].slice(-2)}`,
            card_number: this.state.card_number.replace(' ', ''),
            card_cvv: this.state.card_cvv
        };

        let cardValidations = window.pagarme.validate({ card: card });
        // ak_test_wwjsjA8I6tsI0u8306eQPfXAwWVfoP
        window.pagarme.client.connect({ encryption_key: CONFIG.PAGARME })
            .then(client => client.security.encrypt(card))
            .then(card_hash => {
                if (!cardValidations.card.card_number) {
                    console.log('Antes: ', this.state.card_valid, this.state.card_cvv, this.state.card_doc, this.state.data_nascimento );
                    this.setState({
                        loading: false,
                        // name_card: '',
                        card_number: '',
                        card_valid: '',
                        card_cvv: '',
                        // card_doc: '',
                        // data_nascimento: ''
                    }, () => {
                        alert('Opss! Os dados do cartão estão errados. Por favor verifique.');
                    });
                    // console.log('Depois: ', this.state.card_valid, this.state.card_cvv, this.state.card_doc, this.state.data_nascimento );
                    
                } else {
                    this.setState({
                        card_hash: card_hash,
                        card_number: '',
                        card_cvv: '',
                        card_valid: ''
                    });
                    this.fechamento();
                }
            })


        // console.log('STATE: ', this.state);
    }

    fechamento() {
        ListServices.createCustomer({
            token: CONFIG.TOKEN,
            key: CONFIG.KEY,
            nome: this.state.name,
            telefone: this.state.telefone_cliente,
            empresa_id: this.props.userInfo[0].empresa_id,
            email: this.state.email_cliente
        }).then((res) => {
            console.log('FECHAMENTO: ', res);
            this.setState(
                {
                    cliente_id: res.success[0].pessoa_id,
                    cupom_id: this.state.cupom_id,
                    nome: this.state.name,
                    email: this.state.email_cliente,

                    cpf_titular: this.state.card_doc,
                    endereco: this.state.logradouro_cliente,
                    numero: this.state.numero_cliente,
                    complemento: this.state.complemento_cliente,
                    bairro: this.state.bairro_cliente,
                    cep: this.state.cep_cliente,
                    cidade: this.state.localidade_cliente,
                    uf: this.state.uf_cliente,
                    dd: this.state.telefone_cliente.substring(1, 3),
                    celular: this.state.telefone_cliente.substring(4, 15),
                    nome_titular: this.state.name_card,
                    data_nascimento_titular: this.state.data_nascimento,
                    ddd_titular: this.state.celular_titular.substring(1, 3),
                    celular_titular: this.state.celular_titular.substring(
                        4,
                        15
                    ),
                    total: this.state.valor,
                    // tokencartao: this.state.tokencartao,
                    //     ? window.PagSeguroDirectPayment.getSenderHash()
                    //     : "",
                },
                () => {

                    // console.log("ALL", this.state);

                    this.complete();
                }
            );
        })
            .catch((err) => {
                console.log("Deu Erro", err);
            });
    }

    async complete() {
        // this.props.updateInfo(this.state);

        this.setState({
            loading: true,
        });

        console.log('customer_id', this.state)

        await ListServices.createService(this.state)
            .then((res) => {
                // console.log("Agendamento");
                console.log('OBJ: ', this.state);
                console.log("Resposta: ", res);
                if(res.qrcodepix != null || res.qrcodepix != undefined){
                    sessionStorage.setItem('qrcodepix', res.qrcodepix);
                }
                if (
                    res.success ===
                    "Tudo certo: Seu agendamento foi cadastrado com sucesso!"
                ) {
                    this.nextStep();
                    console.log('--->>>>>>>: ', this.state);
                    //swindow.open(res.linkwhats);
                } else if (res.error === "OPSS: Informe um valor!") {
                    alert(res.error);
                    // this.props.updateInfo(this.state);
                    this.setState({
                        loading: false,
                    });
                } else {
                    alert(res.error);
                    this.setState({
                        loading: false
                    });
                    //this.prevStep();
                }
                // const person = {
                //     name: this.state.name,
                //     email_cliente: this.state.email_cliente,
                //     telefone_cliente: this.state.telefone_cliente,
                //     cep_cliente: this.state.cep_cliente,
                //     logradouro_cliente: this.state.logradouro_cliente,
                //     complemento_cliente: this.state.complemento_cliente,
                //     numero_cliente: this.state.numero_cliente,
                //     bairro_cliente: this.state.bairro_cliente,
                //     localidade_cliente: this.state.localidade_cliente,
                //     uf_cliente: this.state.uf_cliente,
                // };
                // window.localStorage.setItem("user", JSON.stringify(person));
            })
            .catch((err) => {
                console.log("Error: ", err);
                this.setState({
                        loading: false
                    });
            });
    }

    async cupom() {
        await ListServices.cupom({
            token: CONFIG.TOKEN,
            key: CONFIG.KEY,
            empresa_id: this.props.userInfo[0].empresa_id,
            cupom_nome: this.state.cupom_nome,
        }).then((res) => {
            let newPrice = this.state.valor,
                valida = false;
            if (res.success) {
                if (res.success[0].tipo === 2) {
                    if (res.success[0].valor >= this.state.valor) {
                        newPrice = 0.0;
                    } else {
                        newPrice = this.state.valor - res.success[0].valor;
                    }

                    alert(
                        `Você usou o cupom ${this.state.cupom_nome}. E ganhou R$ ${res.success[0].valor} de desconto`
                    );
                } else {
                    let porcent =
                        (res.success[0].valor / 100) * this.state.valor,
                        per = parseFloat(res.success[0].valor).toFixed();
                    newPrice = this.state.valor - porcent;
                    alert(
                        `Você usou o cupom ${this.state.cupom_nome}. E ganhou ${per}% de desconto`
                    );
                }
                valida = true;
                this.setState({
                    valor: newPrice,
                    disabled_cupom_input: valida,
                    disabled_cupom_btn: valida,
                    cupom_id: res.success[0].id,
                });
            } else {
                // console.log("Retorno Cupom invalido", res);
                if (
                    res.error ===
                    "OPSS: Cupom informado não existe ou está expirado!"
                ) {
                    alert(
                        `O Cupom informado: ${this.state.cupom_nome} não existe ou está expirado!.`
                    );
                }
                valida = false;
            }
        });
    }

    formartCurrency(value) {
        const money = parseFloat(value),
            convert = money.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
            });
        return convert;
    }

    sumValues() {
        this.setState({
            ...this.state,
            valor: parseFloat(this.props.serviceValue.valor),
        });
    }

    invalidEmail(value) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    }

    validateEmail = (e) => {
        const email = e.target.value;
        if (!email || this.invalidEmail(email)) {
            this.setState({
                validEmail: true,
            });
        } else {
            this.setState({
                validEmail: false,
            });
            alert("O E-mail informado está incorreto");
        }
    };

    validaEndereco = async () => {
        this.setState({
            loading: true,
        });
        await ListServices.searchZipCode(this.state.cep_cliente).then((res) => {
            console.log('--->>>>> CEP: ', res);
            this.setState({
                cep_cliente: res.cep,
                logradouro_cliente: res.logradouro,
                bairro_cliente: res.bairro,
                localidade_cliente: res.localidade,
                uf_cliente: res.uf,
                loading: false,
            });
        }, err => {
            alert('Por Favor, informe o CEP da sua rua corretamente!');
            this.setState({
                loading: false,
            });
        });
        
    };

    temCupom() {
        const url = new URL(window.location.href),
            search = url.searchParams.get("cupom");
        if (search) {
            this.setState(
                {
                    cupom_nome: search.toUpperCase(),
                },
                () => {
                    this.cupom();
                }
            );
        }
    }

    render() {
        const {
            loading,
            disabled_cupom_btn,
            disabled_cupom_input,
        } = this.state;
        var disabled, disabled_pgto;
        if (
            this.state.name !== "" &&
            this.state.telefone_cliente !== "" &&
            this.state.tipo_pagamento !== ""
        ) {
            disabled = false;
        } else {
            disabled = true;
        }

        if(this.state.tipo_pagamento === 'C'){
            console.log('Cartão: ', this.state.card_number.trim().length);
            disabled = true;
            if(this.state.name_card !== "" && 
            this.state.card_number.trim().length === 19 && 
            this.state.card_valid.trim().length === 5 && 
            this.state.card_cvv.trim().length >= 3 &&
            this.state.card_doc !== "" &&
            this.state.data_nascimento.trim().length === 10 && 
            this.state.cep_cliente !== "" && 
            this.state.logradouro_cliente !== "" && 
            this.state.bairro_cliente !== "" && 
            this.state.numero_cliente !== ""
            
            ){
                disabled = false;
            }else{
                disabled = true;
            }
        } else if(this.state.tipo_pagamento === 'P' && !this.state.pgCartao){
            disabled = true;
            if(this.state.email_cliente !== "" && this.state.card_doc !== "" && this.state.data_nascimento.trim().length === 10 ){
                disabled = false;
            }else{
                disabled = true;
            }
        }

        if(this.state.valor == 0){
            console.log('AQUI 1');
            if (
                this.state.name !== "" &&
                this.state.telefone_cliente !== ""
            ) {
                disabled = false;
                console.log('AQUI 2');
            } else {
                disabled = true;
                console.log('AQUI 3');
            }
        }

        // else{
        //     disabled = false;
        // }

        // verificaCliente()
        // 
        //     this.state.localidade_cliente !== "" && 
        //     this.state.uf_cliente !== ""
        
        console.log('--->>>', disabled);

        if (this.state.disabled_pgto_btn) {
            disabled_pgto = <div className="obrigatorio"> * é necessário preenhcer os campos de identificação.</div>
        } else {
            disabled_pgto = ''
        }

        return (
            <Fragment>
                <div className="user-identification">
                    <div className={loading ? "loading" : "no-loading"}>
                        <img
                            src={iconLoading}
                            alt="Carregando"
                            className="ajax-load"
                        />
                    </div>
                    <div className="title">Identificação do Cliente</div>
                    <div className="form">
                        <input
                            className="input"
                            name="name"
                            value={this.state.name}
                            onChange={this.user}
                            placeholder="Nome Completo"
                        />
                        <InputMask
                            className="input"
                            name="telefone_cliente"
                            value={this.state.telefone_cliente}
                            onChange={this.user}
                            placeholder="Telefone com DDD (obrigatório)"
                            mask="(99) 99999-9999"
                            maskChar=" "
                            type="tel"
                        />
                        {disabled_pgto}
                    </div>

                    {/* <div className="form">
                        <input
                            className="input"
                            name="email_cliente"
                            value={this.state.email_cliente}
                            onChange={this.user}
                            placeholder="Seu e-mail (opcional)"
                            onBlur={this.validateEmail}
                            type="email"
                        />
                    </div> */}

                    {/* <div className="title">Aplicar cupom de desconto</div> 
                    <div className="form">
                        <input
                            className="input"
                            name="cupom_nome"
                            disabled={disabled_cupom_input}
                            value={this.state.cupom_nome}
                            onChange={this.user}
                            placeholder="Insira seu cupom de desconto"
                        />
                        <button
                            className="cupom"
                            disabled={disabled_cupom_btn}
                            onClick={() => {
                                this.cupom();
                            }}
                        >
                            Usar Cupom
                        </button>
                    </div>
                    */}
                    <div style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor == 0) ? "block" : "none",
                            }}>
                                Serviço grátis!
                    </div>
                    <div className="title" style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "block" : "none",
                            }}>Escolha uma forma de pagamento</div>
                    
                    <div className="form">
                        <input
                            
                            type="radio"
                            id="pgto_pix"
                            name="tipo_pagamento"
                            value="P"
                            onClick={this.user}
                            onChange={this.verificaCliente}
                            disabled={this.state.disabled_pgto_btn}
                            checked={this.state.tipo_pagamento === "P"}
                            style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "block" : "none",
                            }}
                        />
                        <label
                            htmlFor="pgto_pix"
                            style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "flex" : "none",
                            }}
                        >
                            <span className="check-custom">
                                <span></span>
                            </span>
                            <img
                                src={cartaoPix}
                                alt="Pagamento com Pix"
                                className="icone"
                            />{" "}
                            Pix
                        </label>

                        {/* Opt Cartão Opt Cartão */}
                        <input
                            type="radio"
                            id="credit"
                            name="tipo_pagamento"
                            value="C"
                            onClick={this.user}
                            onChange={this.verificaCliente}
                            disabled={this.state.disabled_pgto_btn}
                            checked={this.state.tipo_pagamento === "C"}
                            style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "block" : "none",
                            }}
                        />
                        <label
                            htmlFor="credit"
                            style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "flex" : "none",
                            }}
                        >
                            <span className="check-custom">
                                <span></span>
                            </span>
                            <img
                                src={cartaoCredito}
                                alt="Cartão de Crédito"
                                className="icone"
                            />{" "}
                            Cartão de Crédito
                        </label>
                        {/* <div className="opt-payment" style={{
                                display: (this.props.banco != null && this.props.exibe != null && this.state.valor != 0) ? "block" : "none",
                            }}>
                            <button
                                onClick={this.otherPayment}
                                disabled={this.state.disabled_pgto_btn}
                            >
                                <img src={changePayment} alt="Outras formas de Pagamento"/>
                            </button>    
                        </div> */}
                        {/* <input
                            type="radio"
                            id="money"
                            name="tipo_pagamento"
                            value="D"
                            disabled={this.state.disabled_pgto_btn}
                            onClick={this.user}
                            checked={this.state.tipo_pagamento === "D"}
                            style={{
                                visibility: this.state.otherPayment ? "visible" : "hidden",
                            }}
                        />
                        <label 
                            htmlFor="money"
                            style={{
                                visibility: this.state.otherPayment ? "visible" : "hidden",
                            }}
                        >
                            <span className="check-custom">
                                <span></span>
                            </span>
                            <img
                                src={dinheiro}
                                alt="Pagar no estabeleciomento"
                                className="icone"
                            />{" "}
                            Pagar no estabelecimento
                        </label> */}
                        <div className="tab-panel">
                            {/* Opt Cartão */}
                            <div className="tab">
                                <div>
                                    <div style={{
                                        display: !this.state.pgCartao || this.state.customer_id == null
                                            ? "block"
                                            : "none",
                                    }}>
                                        <div className="title">Cadastre-se</div>

                                        <div className="form">
                                            <input
                                                className="input"
                                                name="email_cliente"
                                                value={this.state.email_cliente}
                                                onChange={this.user}
                                                placeholder="Seu e-mail"
                                                onBlur={this.validateEmail}
                                                type="email"
                                            />
                                            <InputMask
                                                className="input"
                                                name="card_doc"
                                                placeholder="CPF"
                                                mask="999\.999\.999\-99"
                                                onChange={this.card}
                                                maskChar=" "
                                                type="tel"
                                            />

                                        </div>
                                        <div className="form">
                                            <InputMask
                                                className="input"
                                                name="data_nascimento"
                                                placeholder="Data de Nascimento"
                                                mask="99/99/9999"
                                                onChange={this.card}
                                                maskChar=" "
                                                type="tel"
                                            />
                                            <select class="select" onChange={this.chooseCountry}>
                                                <option value="0" disabled>Selecione um país</option>
                                                <option value="BR" selected>Brasil</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div style={{
                                        display: this.state.tipo_pagamento === 'C'
                                            ? "block"
                                            : "none",
                                    }}>
                                    <div className="title">Dados do Cartão</div>
                                    <div className="flip-card">
                                        <div className={"flip-card-inner " + (this.state.flip_card ? 'flip-back' : 'flip-front')}>
                                            <div className="flip-card-front">
                                                <div className="flip-name">
                                                    {this.state.name_card}
                                                </div>
                                                <div className="flip-number">
                                                    {this.state.card_number}
                                                </div>
                                                <div className="flip-data">
                                                    {this.state.card_valid}
                                                </div>
                                            </div>
                                            <div className="flip-card-back">
                                                <div className="flip-ccv">
                                                    {this.state.card_cvv}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form">
                                        <input
                                            className="input"
                                            name="name_card"
                                            onChange={this.card}
                                            placeholder="Nome do Titular do Cartão"
                                            value={this.state.name_card}
                                        />
                                        <InputMask
                                            className="input"
                                            name="card_number"
                                            onChange={this.card}
                                            // onBlur={this.bandeiraCartao}
                                            placeholder="Número do Cartão"
                                            value={this.state.card_number}
                                            mask="9999 9999 9999 9999"
                                            maskChar=" "
                                            type="tel"
                                        />
                                    </div>

                                    <div className="form">
                                        <InputMask
                                            className="input"
                                            name="card_valid"
                                            value={this.state.card_valid}
                                            onChange={this.card}
                                            onBlur={this.validYear}
                                            placeholder="Validade (mês/ano) Ex.: 10/23"
                                            mask="99/99"
                                            maskChar=" "
                                            type="tel"
                                            
                                        />
                                        <InputMask
                                            className="input"
                                            name="card_cvv"
                                            maxLength="4"
                                            onFocus={this.flip}
                                            onBlur={this.verso}
                                            onChange={this.card}
                                            value={this.state.card_cvv}
                                            type="tel"
                                            onBlur={this.validCvv}
                                            maskChar="9999"
                                            placeholder="CVV (Código de segurança)"
                                        />
                                        
                                    </div>
                                    <div className="form">
                                        <InputMask
                                            className="input"
                                            name="card_doc"
                                            placeholder="CPF do titular"
                                            mask="999\.999\.999\-99"
                                            onChange={this.card}
                                            value={this.state.card_doc}
                                            maskChar=" "
                                            type="tel"
                                        />

                                        <InputMask
                                            className="input"
                                            name="data_nascimento"
                                            value={this.state.data_nascimento}
                                            onChange={this.card}
                                            placeholder="Data de Nascimento do titular"
                                            mask="99/99/9999"
                                            maskChar=" "
                                            type="tel"
                                        />
                                    </div>

                                    {/* <div className="form">
                                        <InputMask
                                            className="input"
                                            name="celular_titular"
                                            placeholder="Telefone do Titular do Cartão"
                                            mask="(99) 99999-9999"
                                            onChange={this.card}
                                            maskChar=" "
                                        />
                                    </div> */}
                                    <div className="title">Endereço</div>
                                    <div className="form">
                                        <InputMask
                                            className="input"
                                            name="cep_cliente"
                                            onBlur={this.validaEndereco}
                                            value={this.state.cep_cliente}
                                            onChange={this.user}
                                            placeholder="CEP"
                                            mask="99999-999"
                                            maskChar=" "
                                            
                                        />
                                    </div>
                                    <div className="form">
                                        <input
                                            className="input"
                                            type="text"
                                            name="logradouro_cliente"
                                            value={
                                                this.state.logradouro_cliente
                                            }
                                            onChange={this.user}
                                            placeholder="Endereço"
                                        />
                                        <input
                                            className="input"
                                            type="text"
                                            name="bairro_cliente"
                                            value={
                                                this.state.bairro_cliente
                                            }
                                            onChange={this.user}
                                            placeholder="Bairro"
                                        />
                                    </div>
                                    <div className="form">
                                        <input
                                            className="input"
                                            type="text"
                                            name="numero_cliente"
                                            value={this.state.numero_cliente}
                                            onChange={this.user}
                                            placeholder="Número"
                                        />
                                        <input
                                            className="input"
                                            type="text"
                                            name="complemento_cliente"
                                            value={
                                                this.state.complemento_cliente
                                            }
                                            onChange={this.user}
                                            placeholder="Complemento"
                                        />
                                    </div>
                                    <div className="form">
                                        <input
                                            className="input"
                                            type="text"
                                            name="localidade_cliente"
                                            value={
                                                this.state.localidade_cliente
                                            }
                                            onChange={this.user}
                                            placeholder="Cidade"
                                        />
                                        <input
                                            className="input"
                                            type="text"
                                            name="uf_cliente"
                                            value={this.state.uf_cliente}
                                            onChange={this.user}
                                            placeholder="Estado"
                                        />
                                    </div>
                                    {/*  */}
                                    </div>
                                </div>
                            </div>
                            <div className="tab">
                                Você escolheu pagar no estabelecimento pelo seu
                                atendimento no valor de{" "}
                                <strong>
                                    {this.formartCurrency(this.state.valor)}
                                </strong>{" "}
                            </div>
                            <div className="tab">
                                Você escolheu pagar com PIX. O valor do atendimento é de: <strong>
                                    {this.formartCurrency(this.state.valor)}
                                </strong>.  Clique em Concluir para prosseguir.
                            </div>
                        </div>
                    </div>
                    <div className="action">
                        <button className="prev" onClick={this.prevStep}>
                            Etapa Anterior
                        </button>
                        <button
                            className="next"
                            onClick={() => {
                                this.pagar();
                                // this.nextStep();
                            }}
                            disabled={disabled}
                        >
                            Concluir
                        </button>
                        
                    </div>
                </div>
            </Fragment>
        );
    }
}
